import React, {useState} from 'react';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import PlatformExample from './PlarformExample';
import PopularExample from './PopularExample';
import WrapperStyle from './examples.style';
import Drawer from '../../components/UiComponent/drawer';
import ScriptTag from 'react-script-tag';
import {StaticImage} from 'gatsby-plugin-image';

const OurSolutions = () => {
  const [visible, setVisible] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState(null);
  const showDrawer = (id, title) => {
    setVisible(true);
    setPromotionId(id);
    setDrawerTitle(title)
  };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h1>Twitterのキャンペーン事例・サンプル
              </h1>
              <p>
                Twitterによくあるフォロー・リツートキャンペーンからハッシュタグツイート・画像投稿・いいねキャンペーンからハッシュタグを自動収集してUGCギャラリーやツイートコンテストも開催できます。
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <a data-retach-widget="embed" href="https://retach.biz/promotions/ff1874b719911e38a9" rel="nofollow">リタッチ</a>
                <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
              </div>
            </div>
          </div>
        </section>
        <PlatformExample title="Twitterのキャンペーンサンプル" types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag', 'twitterToLine','twitterInstantWin','twitterMultipleAccounts']} showDrawer={showDrawer}/>
        <section className="ptb-100 top-question" >
          <div className="container">
            <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
              <h2 id="question">よくあるご質問</h2>
            </div>
            <div className="faq-accordion">
              <Accordion allowMultipleExpanded={true}>
                <AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Twitterのリツートとシェア応募は何がちがいますか？
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        リツートはリツートしたアカウントのフォロワーに知らせる行為となりますので、認知の拡大になりますが興味があるユーザーと興味がない方にも知らせることになります。<br/>
                        シェア応募はシェアされた人がキャンペーンに１つ以上応募することですることで、応募完了となり当選確率が上がります。 つまり多くの参加者が応募してくれそうな人を自ら選定してから告知してくれます。<br/>
                        多くの参加者を集めてくれた応募者が有利になるように設計されています。
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        応募したTwitterアカウント毎に住所を入力してもらうことは可能ですか？
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        はい。Twitterのログインが必要な(エントリー、ツイート、リツイート、フォロー、ハッシュタグ、いいね、画像を選択)を１つ以上使用していただければ、Twitterアカウントと住所を紐づけることができます。
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Twitterのインスタントウィンを行いたいのですが、認証バッチは必要ですか？
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        いいえ。認証バッチがなくてもインスタントウィンを開催できます。
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      リツートは自動で取得できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      いいえ。自動で取得しておりません。応募フォームからご応募頂くことで応募完了となります。アプリで応募するよりアクションが増えますが、他のSNSと組み合わせたり、友達にシェアしてもらったりアプリだけではできないメリットがたくさんあります。<br/>
                      その他にも応募フォームから応募してもらうことで、応募完了がわかりやすくなります。実際にアプリのみで行うキャンペーンでは、応募条件を満たしているのかわからないといったお問い合わせが多数あります。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      応募者は毎回Twitterでのログインが必要ですか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      いいえ。過去にリタッチを使用したTwitterアカウントであれば自動でログインされます。<small>（応募者がTwitterのログインセッションを切っている場合は除きます）</small>
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>
            </div>
          </div>
        </section>
        <PopularExample exclude={['twitter','twitterToLine', 'twitterMultipleAccounts']} excludeProviders={['twitter']} showDrawer={showDrawer}/>
        <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions