import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import TwitterCampaign from '../../../components/Examples/TwitterCampaign'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter.jpg';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Twitterのキャンペーン事例・サンプル"
		  desc="Twitterのリツイート・フォロー・ハッシュタグ・ツイート・画像収集などの応募方法からTwitterでキャンペーンを行う方法です。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter'}
		  type={'Article'}
		  shortName="Twitterのキャンペーン事例・サンプル"
		  createdAt="2021-07-19"
		/>
	  <Navbar />
	  <ContentBanner
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitterキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <TwitterCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples